body {
  background-color: brown;
  overflow-x: hidden;
}

.title {
  color: #fff;
}
.title small {
  font-size:medium;
  color: #CCC;
}

.btn-help {
  margin-top: 1rem;
}

.panel {
  background-color: #fff;
  margin-top: 15px;
  padding: 15px;
}

.selector {
  column-gap: 30px;
  column-rule: 1px solid #ccc;
}

@media (max-width: 610px) { 
  .selector {
    font-size: x-large;
    column-count: 1;
  }
}

@media (min-width: 610px) { 
  .selector {
    font-size: x-large;
    column-count: 2;
  }
}

@media (min-width: 992px) { 
  .selector {
    font-size: x-large;
    column-count: 3;
  }
}

.char-table td {
  padding:4px 4px;
}
.selected-prefix-area {
  margin: 0px 15px 25px;
  display: flex;
  align-items: center;
}
.selected-prefix-area > button {
  fill: #6c757d;
}
.selected-prefix-area > button:hover {
  fill: #ffffff;
}
.selected-prefix {
  display: inline;
  margin: 2px;
  padding: 4px 12px;
  font-size: xx-large;
}
.choice {
  color:#007bff;
  border:0;
  background: none;
  text-align: left;
}
.choice:hover { color:#007bff; }
.choice-selected {
  background-color:#007bff;
  color:#fff;
}
.choice-selected:hover { color:#fff; }
.choice-cannot {
  color:#CCCCCC;
  border:0;
  background: none;
  text-align: left;
  margin: 0;
  padding-top: 2px;
  padding-right: 6px;
  padding-bottom: 3px;
  padding-left: 6px;
  display: inline-block;
}
.choice-cannot:hover { color:#CCCCCC; }
.word-ref { 
  font-size:medium; 
  margin-right: 7px;
}

.btn-koumoku-disabled { 
  background-color: #CCCCCC !important;
}

.weblio, .jk {
  color:black;
  margin-left:1em;
  font-weight: bold;
  text-decoration: none;
}
.weblio .w {
  color:#c17b94;
}
.jk .k {
  color:#623e95;
}

.weblio:hover, .jk:hover {
  color:black;
}
.verticaltable {
  font-family: "UD デジタル 教科書体 N-R", "UD Digi Kyokasho N-R", "AR楷書体M", "HGS教科書体", sans-serif;
  background-color: #fff;
  width: 100%;
  height: 150%;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  -webkit-text-orientation: mixed;
}
.verticaltable td {
  vertical-align: middle !important;
  border:1px solid #CCC;
  padding:0.5rem;
}
.verticaltable .tw {
  height:7rem;
  text-align: center;
}
.verticaltable .tjk {
  padding:1rem;
}
.verticaltable .jk {
  writing-mode: horizontal-tb;
  margin:0px 10px;
}

.swipe {
  overflow: hidden;
  visibility: hidden;
  position: relative;
}
.swipe-wrap {
  overflow: hidden;
  position: relative;
}
.swipe-wrap > div {
  float: left;
  width: 100%;
  position: relative;
}

.search-tabs .nav-link {
  color: #CCC;
}
.search-tabs .nav-link:hover, .search-tabs .nav-link:focus {
  border-color: #5c636a #5c636a #dee2e6 !important;
  color: white;
  background: #5c636a;
}
.search-tabs .nav-link.active, .search-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff !important;
}

.koumoku-table {
  table-layout: fixed;
  width: 8em;
}
